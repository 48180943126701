@media screen and (max-width: 800px)
	main#Contact
		text-align: center
		margin-top: 6em
		
	#Contact__parent-callout
		margin: .7rem 0

	#EmailButton__text-short
		display: inline

	#EmailButton__text-long
		display: none

	#Contact__img-wrapper img
		width: 80%

@media screen and (max-width: 600px)
	#Contact__grid
		margin: 0 5rem 

@media screen and (max-width: 500px)
	main#Contact
		& h1
			font-size: 24pt

@media screen and (max-width: 400px)
	main#Contact
		margin-top: 8em

	#Contact__grid
		margin: 0 2rem 

	