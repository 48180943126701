.Circle
	position: absolute
	display: block
	border-radius: 50%
	
.FounderBlurb
	overflow: hidden

	background-color: white
	border: solid #e4dede 15px
	display: grid
	grid-template-columns: 1fr auto

	&[data-left]
		grid-template-columns: auto 1fr

.FounderBlurb__circles
	pointer-events: none
	position: relative
	grid-column: 1
	grid-row: 1

.FounderBlurb__image-section
	display: grid

	& img
		width: 500px
		height: 500px

		margin: auto 0

		grid-column: 1
		grid-row: 1
		border-radius: 50%

		scale: 80%
		transition: scale 150ms, rotate 200ms

		object-fit: cover

		&:hover
			rotate: 360deg
			scale: 90%

.FounderBlurb__text-section
	padding-left: 50px

	& h3
		font-size: 18pt

	& p
		padding: 30px
		border-radius: 10px
		background-color: #e4dede