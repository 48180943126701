@media screen and (max-width: 800px)
	main#Mission
		text-align: center
		margin-top: 6em
		
	#Mission__parent-callout
		margin: .7rem 0

	#Mission__img-wrapper img
		width: 80%

@media screen and (max-width: 600px)
	#Mission__grid
		margin: 0 5rem 

@media screen and (max-width: 500px)
	main#Mission
		& h1
			font-size: 24pt

@media screen and (max-width: 400px)
	main#Mission
		margin-top: 8em
		
	#Mission__grid
		margin: 0 2rem 

	