@media screen and (max-width: 1000px)
	#Join__bottom-img img
		width: 70%

@media screen and (max-width: 800px)
	main#Join
		text-align: center
		margin-top: 6em

	#Join__overview
		grid-template-columns: 1fr

@media screen and (max-width: 600px)
	.Join__mx
		margin: 0 5rem

	#Join__sounds-cool
		display: none

@media screen and (max-width: 500px)
	main#Join
		& h1
			font-size: 24pt

@media screen and (max-width: 400px)
	main#Join
		margin-top: 8em

	.Join__mx
		margin: 0 2rem
