main#Donate
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center

	margin-top: 6em
	min-height: 90vh

	& h1
		font-size: 36pt
		text-align: center

	& img
		width: 80%
		display: block
		margin: 2rem auto

#Donate_box
	display: flex
	flex-direction: column
	background-color: white
	margin: 0 10rem
	padding: 2rem

