main#Contact
	margin-top: 6em
	min-height: 90vh

	& h1
		font-size: 36pt
		text-align: center

	& img
		display: block
		margin: 2rem auto

#Contact__grid
	background-color: white
	margin: 0 10rem 

#Contact__info
	margin: 0 auto
	padding: 2rem

#Contact__parent-callout
	display: block

#EmailButton__text-short
	display: none