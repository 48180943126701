@use '../../index'

// @media screen and (min-width: 1200px)
// 	.div-as-img
// 		background-size: cover

@media screen and (min-width: 1900px)
	.ParallaxImageTextSection__text-content
		font-size: 18pt

	.important-left-items
		width: 20%


	.LocationBar__location-bar
		font-size: 150%

	.ImageCarousel__image-carousel-blurb
		font-size: 200%

	.ImageCarousel__image-carousel
		& .MuiAvatar-root, & button
			width: 70px
			height: 70px

		& svg
			width: 40px
			height: 40px

	#theory-explanation
		width: 70%
		font-size: 200%

	.ParallaxImageTextSection
		font-size: 170%

	#visit-us, .Dot__carousel-dot
		font-size: 60pt

	.Landing__footer
		font-size: 200%

@media screen and (min-width: 2500px)
	#footer-location-title
		font-size: 150%

	.ParallaxImageTextSection__text-content
		font-size: 24pt

@media screen and (max-width: 1450px)
	.important-left-items
		width: calc(index.$logo-and-welcome-width*1.3)

	#theory-title
		font-size: 24pt

	.ParallaxImageTextSection__text-content
		font-size: 14pt

	#footer-credits
		margin-right: 50px

@media screen and (max-width: 1200px)
	// #hero-wrapper
	// 	height: min(max(60vh, 350px), 500px)

@media screen and (max-width: 1100px)
	.important-left-items
		width: calc(index.$logo-and-welcome-width*1.3)

	#theory-title
		font-size: 18pt

@media screen and (max-width: 900px)
	// #hero-wrapper
	// 	height: min(max(60vh, 270px), 300px)

	.important-left-items
		width: calc(index.$logo-and-welcome-width*1.5)

	#footer-location-quote
		display: none

	#footer-credits
		font-size: 90%

	#visit-us
		font-size: 28pt

@media screen and (max-width: 800px)
	.important-left-items
		width: calc(index.$logo-and-welcome-width*1.7)

	#theory-explanation
		width: 65%

	#theory-title
		font-size: 14pt

	.ParallaxImageTextSection
		overflow: hidden
		grid-template-columns: 1fr !important
		grid-template-rows: 1fr 1fr !important

	.ParallaxImageTextSection__text-section-image
		grid-column-start: 1
		grid-row-start: 1

	.ParallaxImagesSection__bg > svg
		display: none

	#visit-us
		font-size: 22pt

@media screen and (max-width: 700px)
	#Header__items
		font-size: 100% !important
		flex-direction: column
		justify-content: center

		& > *
			margin: auto 20%

	.important-left-items
		width: calc(index.$logo-and-welcome-width*2)

	.LocationBar__location-bar
		padding: 5px

		& > span
			display: none

	.landing-content-spacing
		margin: 1rem

	#footer-location-title
		font-size: 20pt

@media screen and (max-width: 600px)
	.ParallaxImageTextSection
		grid-template-rows: 1fr 1.5fr !important

	#footer-location-about-desktop
		& td, & th
			padding: 0 15px

		& td
			font-size: 10pt

	.Landing__footer
		padding: 1rem

	// #hero-wrapper
	// 	height: min(max(60vh, 150px), 250px)

@media screen and (max-width: 500px)
	.Landing__footer
		padding: 2rem

	#theory-title
		font-size: 12pt

	#theory-explanation
		width: 80%

	#footer-location
		grid-template-columns: auto

	#footer-location-title
		width: 100%
		height: min-content // for safari

		display: block
		padding-bottom: 5px

		padding-right: 0

		text-align: center
		text-decoration: none
		border-bottom: solid white 2px
		font-size: 26pt

	#footer-location-about-desktop
		display: none

	#footer-location-about-mobile
		display: flex
		flex-direction: column
		align-items: center
		text-align: center

		& > *
			font-size: 18pt
			margin-top: 30px

	#visit-us
		font-size: 18pt

	// #hero-wrapper
	// 	height: min(max(60vh, 125px), 200px)

// Switch to mobile
@media screen and (max-width: 400px) //, (orientation: portrait)
	section#hero
		display: flex
		flex-direction: column

	.standard-image
		max-width: 200px !important

	.LocationBar__location-bar
		display: none

	.important-left-items
		width: 100%

	#theory-explanation
		width: 90%
		padding: 1.25rem

	#footer-credits
		margin-right: 0
		text-align: center

	.ParallaxImagesSection__wrapper
		display: flex
		flex-direction: column

	.SupportSection__images
		& > img
			&[data-do-not-render-mobile]
				display: none

				padding: 0 20%
				max-width: none !important
				width: 100% !important

	#visit-us
		font-size: 16pt

	.ParallaxImageTextSection
		text-align: center

@media screen and (max-width: 350px)
	#footer-location-about-mobile > *
		font-size: 14pt

@media screen and (max-width: 250px)


	.Landing__footer
		padding: 1rem

	#footer-location-about-mobile > *
		font-size: 10pt

	#footer-location-title
		font-size: 18pt
