@use '../../App'
@use '../../index'

$carousel-delay: 500

@mixin border($color)
	border: $color solid 2px
	box-sizing: border-box

.LocationBar__location-bar
	display: flex
	align-self: center
	align-items: center
	margin-top: 10px

	background-color: index.$color-page
	text-transform: uppercase
	font-weight: bold
	color: #807777

	padding: 1rem
	padding-left: 2rem
	padding-right: 2rem

	border-radius: 100vmax

	& > span
		margin-right: 10px
	& > a
		display: flex

.important-left-items
	vertical-align: middle
	width: index.$logo-and-welcome-width
	padding: 1rem

.landing-content-spacing
	// margin-left: 2rem
	// margin-right: 2rem

.landing-content-v-spacing
	margin-top: 3rem
	margin-bottom: 3rem

.ImageCarousel__image-carousel
	margin-top: 1rem
	height: 100%
	margin-bottom: 1rem
	background-color: index.$color-page
	box-sizing: border-box
	position: relative

.div-as-img
	// height: 100%
	width: 100%
	height: 0

	padding-top: 24% // (img-height / img-width * container-width)
	// (1080 / 450 * 100)

	background-repeat: no-repeat
	background-size: contain
	background-position: center



.ImageCarousel__image-carousel-img
	box-shadow: inset 0px -20px 15px #5042428a

#supporters
	padding-bottom: 2rem

.Landing__footer
	max-width: 100%
	height: 100%

	padding: 2rem

	background-color: index.$color-main
	color: index.$color-page

	display: flex
	flex-direction: column

#footer-location
	display: grid
	grid-template-columns: auto auto 1fr
	align-items: center
	margin-bottom: 30px

#footer-location-title
	display: flex
	align-items: center
	font-weight: bold

	text-decoration: underline
	font-size: 24pt
	padding-right: 30px

#footer-location-about-desktop
	& td, & th
		border-left: solid white 2px
		padding: 0 30px
	// & td a
	// 	font-size: inherit
	& th
		text-align: left

#footer-location-about-mobile
	display: none // hide until media query

#footer-location-quote
	text-align: right

	margin-left: auto

	padding: 20px
	border: solid white 4px

#copyright
	display: block
	text-align: center
	color: #b7c0b8
	&::before
		content: '©'
		margin-right: .5rem

.ImageCarousel__image-carousel-blurb
	position: absolute
	font-size: 120%

	z-index: 3
	left: 0
	bottom: 0

	background-color: index.$color-page
	box-shadow: inset -5px 5px 10px #937e7e63

@keyframes fade-out
	0%
		opacity: 1
	100%
		opacity: 0

.ImageCarousel__fade-out
	@include App.fade-out($duration: #{$carousel-delay}ms)

.ImageCarousel__fade-in
	@include App.fade-in($duration: #{$carousel-delay}ms)

.Landing__fade-in
	@include App.fade-in($duration: 2s)

.Header__jiggle-header
	@include App.jiggle($duration: .5s)

.Header__dial
	position: fixed
	bottom: 20px
	right: 20px

	&:not(:hover)
		transform-origin: bottom center
		@include App.pulse($duration: 3s)

@keyframes hide-header
	0%
		opacity: 1
	30%
		opacity: .3
	50%
		opacity: 0
		transform: translateY(-100%)
	100%
		opacity: 0
		transform: translateY(0)

.Header__hide-header
	position: absolute !important
	width: 100%
	margin: 0
	border: none

.Dot__carousel-dot
	color: index.$color-page
	font-size: 48pt
	transition: opacity .5s
	opacity: .5

	&[data-on]
		opacity: 1

.ImageCarouselDots__carousel-dots
	position: absolute
	display: flex
	flex-direction: row
	margin: auto

	user-select: none

	bottom: 0
	left: 50%
	right: 50%
	z-index: 3
	justify-self: center

main#Landing
	display: flex
	flex-direction: column

	min-height: 100%

	$tile-width: max(2rem, 40px)

	background-position: $tile-width 0, $tile-width 0, 0 0, 0 0
	background-size: $tile-width $tile-width
	background-repeat: repeat

#hero-wrapper
	// height: min(max(60vh, 400px), 700px)

#visit-us
	opacity: 0
	margin-top: 3rem
	margin-bottom: 3rem

	font-size: 300%

#transition
	height: 100%
	overflow-x: auto
	overflow-y: hidden

	font-size: 0 // to remove SVG gaps

	display: flex
	flex-direction: column

	// grid-template-rows: auto auto auto 1fr

	& > svg
		// flex: 0 0 35%
		object-fit: cover
		font-size: 0 // to remove SVG gaps

		box-sizing: border-box
		// border: solid red 5px
		width: 100%
		height: 20px

		&:first-child
			background-color: index.$color-page

	// & *:last-child:not(svg)
	// 	height: 100%

.single-cell
	grid-column: 1
	grid-row: 1

.ParallaxImagesSection__bg
	background-color: #e4dede

	display: flex
	justify-content: center
	align-items: center

	& > svg
		width: 10%
		height: 10%

.ParallaxImagesSection__wrapper
	z-index: 2
	display: grid

.ParallaxImageTextSection__first-description
	height: 100%
	display: flex
	flex-direction: column

.ParallaxImageTextSection__text-content
	font-size: 16pt
	height: 100%
	display: flex
	flex-direction: column
	justify-content: center

	& div:first-child
		flex-grow: 1

	& div:last-child
		margin-bottom: 8px

#theory-explanation
	margin-top: 5rem

	background-color: index.$color-page
	// box-shadow: inset 0px 5px 10px #5042428a

	width: 60%
	margin-left: auto
	margin-right: auto

	height: max-content
	margin-bottom: 3rem

	border-radius: 20px // 20px

	padding: 2rem
	padding-left: 3rem
	padding-right: 3rem

.TwirlDivider
	text-align: center
	color: white
	height: 20px
	margin-top: 10px
	margin-bottom: 10px

section#hero
	display: contents
	// flex-direction: column

.SupportSection__images
	$number-of-logos: 7

	display: flex
	flex-wrap: wrap
	text-align: center
	align-items: center
	justify-content: center

	margin: 20px

	& > img
		margin-left: 10px
		margin-right: 10px
		flex: 1 0 auto
		max-width: 300px
		display: block

		opacity: 0

	&.SupportSection__fade-in
		@for $i from 0 to $number-of-logos
			& > img:nth-child(#{$i + 1})
				transition: opacity .5s ease-in #{$i * .1}s
				opacity: 1

.MobileHero__main
	// height: 100%
	width: 100%
	margin-top: 7rem
	margin-bottom: 1rem
	position: relative

.MobileHero__focus-image
	position: absolute
	width: 90%
	height: 100%

	left: 5%
	right: 5%
	z-index: 1

	& > [role="img"]
		border-radius: 20px
		filter: blur(6px)
		background-color: #0000001F
		background-blend-mode: multiply

.MobileHero__focus-text
	position: relative

	z-index: 2
	margin-top: 2em

	& h1
		color: #fff
		font-size: 20pt
		background-color: rgb(0,0,0) // Fallback color 
		background-color: rgba(0,0,0, 0.4) // Black w/opacity/see-through 
		text-align: center
		border-radius: 100vmax
		margin: 0 max(1.5em, 20px)
		padding: 1em

.MobileHero__location
	text-align: center
	font-size: 18pt
	font-weight: bold
	margin-top: 1em
	margin: 0 max(1em, 30px)

	& img
		margin: 1em 0
		border-radius: 10px


.MobileHero__focus-image
	position: absolute

	background-position: center
	background-size: cover

	filter: blur(2px)

	display: flex
	flex-direction: column

	height: 100%
	align-content: center

	font-size: 24pt

	left: 20px
	right: 20px

.Quotes
	list-style: none

.Quote
	display: flex
	flex-direction: column

.Quote__author
	display: block
	margin-left: auto
	padding: 0 6em


.Quote__symbol
	display: flex
	flex-direction: column
	justify-content: center

	margin-right: 2rem

	& svg
		font-size: 96pt
		color: rgb(147, 172, 205)

.Quote__content
	flex-grow: 1
	padding: 0 2em

.Quote__emphasis
	text-align: center
	font-size: 150%
	font-weight: bold
	// font-style: italic
	text-decoration: underline dotted
	margin: .5em 0

#mobile-greeting
	margin: 0
	padding: 20px

	border: solid index.$color-splash 5px

	text-align: center
	font-weight: bold

	display: flex
	flex-direction: column

	& > span
		text-shadow: 1px 1px 2px white

#mobile-hero-nav
	margin-top: auto
	margin-bottom: auto

	display: flex
	flex-direction: column
	align-items: center
	justify-content: center

	& > button
		width: 100%

		&:not(:last-child)
			margin-bottom: 10px

#mobile-years-active
	font-size: 12pt
	margin-bottom: 5rem
	text-align: center

section#theory
	position: relative
	display: flex
	flex-direction: column

	background-color: index.$color-main
	margin-top: 2rem
	margin-bottom: 3rem

#theory-title
	text-align: center
	font-style: italic

	padding-top: 2rem
	padding-bottom: .5rem

	font-size: 36pt

	background-color: index.$color-page
	color: #2f2d2d

section#parallax-images
	display: grid
	max-width: 100%

.standard-image
	max-width: 300px

:export
	delay: $carousel-delay

/////////////////////////////////
