.ParallaxImageTextSection
	display: grid
	grid-template-columns: 1fr 2fr
	overflow: hidden

	&:nth-child(odd) 
		grid-template-columns: 2fr 1fr
		
		& > .ParallaxImageTextSection__text-section-image
			grid-column-start: 1
			grid-row-start: 1

	&>.ParallaxImageTextSection__first-description
		background-color: #fff

		padding: 1rem

	&>[data-parallax-image-split]
		min-width: max-content

.parallax-items > :not(:last-child)
	padding-bottom: 8rem

.ParallaxImageSplit__parallax-image-wrapper
	display: flex
	flex-direction: row
	overflow: hidden
	justify-content: center

	height: 100%
	width: 100%

	margin: 0

	& > div
		width: inherit
		height: inherit

	& .ParallaxImageSplit__parallax-image
		opacity: 0
		width: 100%
		height: 100%

		user-select: none

		&[data-fade-first]
			animation: fade-in-parallax-image 1.5s forwards

		&[data-fade-second]
			animation: fade-in-parallax-image 3s .3s forwards

.ParallaxImageTextSection__text-section-image
	display: flex
	align-items: center

@keyframes fade-in-parallax-image
	from
		opacity: 0%
	to
		opacity: 100%