main#Mission
	margin-top: 6em
	min-height: 90vh

	& h1
		font-size: 36pt
		text-align: center

	& img
		display: block
		margin: 2rem auto

#Mission__grid
	background-color: white
	margin: 0 10rem 

#Mission__info
	margin: 0 auto
	padding: 2rem

#Mission__parent-callout
	display: block

#Mission blockquote
	word-break: break-all

	&::before
		counter-increment: mission_citation
		content: counter(mission_citation)
		margin-right: 1em