main#Join
	margin-top: 6em
	width: 100%
	min-height: 90vh


	& h1
		font-size: 36pt
		text-align: center

	& img
		display: block
		margin: 2rem auto

.Join__mx
	margin: 0 10rem

#Join__grid
	background-color: white

#Join__btn
	display: block
	width: fit-content
	margin: 0 auto
	margin-top: 1rem

#Join__info
	margin: 0 auto
	padding: 2rem

#Join__parent-callout
	display: block

#Join__overview
	display: grid
	grid-template-columns: 1fr 1fr
	gap: 4rem

	& h2
		text-align: center

	& > div