@media screen and (min-width: 1200px)
	.FounderBlurb__text-section
		font-size: 150%
		& h2
			font-size: 40pt	
	
@media screen and (min-width: 1900px)
	.FounderBlurb__text-section
		font-size: 200%
		& h2
			font-size: 50pt	

@media only screen and (max-width: 1400px)
	.FounderBlurb__image-section img
		width: 400px
		height: 400px

	.Quote__symbol svg
		font-size: 72pt

@media only screen and (max-width: 1200px)
	.FounderBlurb__image-section img
		width: 300px
		height: 300px

	.FounderBlurb__text-section
		& p
			font-size: 90%
		& h2
			font-size: 24pt

@media only screen and (max-width: 1000px)
	.Quote__symbol
		display: none
	
	.Quotes, .Quote__content
		padding: 0

@media only screen and (max-width: 800px)
	// !important to get around the [left] attribute selection.
	.FounderBlurb
		grid-template-rows: auto auto !important
		grid-template-columns: auto !important
	
	.FounderBlurb__image-section img
		margin: 0 auto

	.FounderBlurb__text-section
		text-align: center
		padding: 0 20px

		& h2
			text-align: center

@media only screen and (max-width: 600px)
	.FounderBlurb__image-section img
		width: 300px
		height: 300px

	.Circle
		font-size: 90% // controls the size of the circle

@media only screen and (max-width: 400px)

	.FounderBlurb__text-section
		padding: 0 15px !important

	.FounderBlurb
		border: solid #e4dede 5px

	.FounderBlurb__image-section img
		width: 250px
		height: 250px

		transition: none

		&:hover
			scale: 80%
			rotate: 0deg

	.Circle
		font-size: 70% // controls the size of the circle

@media only screen and (max-width: 350px)
	.FounderBlurb__image-section img
		width: 175px
		height: 175px
	
	.FounderBlurb__text-section
		& h2
			font-size: 20pt
	
	.Circle
		font-size: 50% // controls the size of the circle

@media only screen and (max-width: 270px)
	.FounderBlurb__image-section img
		width: 120px
		height: 120px

	.Circle
		font-size: 30% // controls the size of the circle

	.FounderBlurb__text-section
		padding: 0 10px !important