main#About
	margin-top: 6em
	width: 100%

#About h1
	font-size: 36pt
	text-align: center

#About h2
	font-size: 28pt

#About section > h3
	font-size: 28pt
	text-align: center
	width: 100%

#About__description
	font-size: 24pt
	width: 800px
	margin: 2em auto
	padding: .5em
	border-radius: 10px
	background: #fff
	margin-bottom: 1.5em

#About__photo-wrapper, #About__leadership
	display: flex
	flex-wrap: wrap
	justify-content: center

#About__photo-wrapper
	margin-left: auto
	margin-right: auto
	max-width: 2500px

#About__leadership
	margin: 2em auto
	max-width: 1800px
	border-bottom: solid black 2px

@keyframes load-image
	0%
		opacity: 0
	50%
		opacity: 0.1
	100%
		opacity: 0

.BlurryPhoto
	width: min-content

	border-radius: 100vmax

	filter: blur(10px)

	background-position: center
	background-repeat: no-repeat
	background-size: cover

	&:not(.BlurryPhoto__loaded)::before
		content: ""
		position: absolute
		inset: 0
		opacity: .3

		animation: load-image 2.5s infinite
		background-color: white

	& img
		object-fit: cover
		width: 200px
		height: 260px
		// height: 200px
		opacity: 0
		transition: opacity 250ms ease-in-out

	&[data-leadership] img
		width: 250px
		height: 325px

	&.BlurryPhoto__loaded
		filter: none

		& img
			opacity: 1

.TeamCard
	display: grid
	grid-template-columns: auto 1fr

	margin: 30px
	width: 350px

	&[data-leadership]
		// border: solid red
		// margin: 50px
		// width: unset
		width: 500px

.TeamCard__text
	margin: 0 20px
	min-width: 150px


#About__group-photo

	& h3
		text-align: center

	& img
		display: block
		margin: 0 auto

		border-radius: 10px

		width: 70%