@media screen and (max-width: 1800px)
	#About__photo-wrapper
		max-width: 1500px
	
@media screen and (max-width: 1400px)
	#About__description
		font-size: 20pt
		width: 600px
	
@media screen and (max-width: 800px)
	#About__description
		font-size: 16pt
		width: 400px

@media screen and (max-width: 650px)
	main#About
		padding: 20px

@media screen and (max-width: 525px)
	#About__description
		font-size: 12pt
		width: 300px

	.TeamCard
		text-align: center
		grid-template-columns: 1fr
		grid-template-rows: auto 1fr

		margin: 1em auto

		padding-bottom: 1em
		border-bottom: dotted black 2px

		&:last-child
			border-bottom: none

		& .BlurryPhoto
			margin: 0 auto

	#About__leadership
		border-bottom: none

	#About__group-photo img
		width: 80%
	

@media screen and (max-width: 400px)
	#About
		margin-top: 70px

	#About h1
		font-size: 28pt

	#About h2, #About h3
		max-width: 300px
		text-align: center

	#About h2
		font-size: 24pt
		font-weight: bold

	#About h3
		font-size: 16pt

	#About .MuiDivider-wrapper
		display: none

	#About__description
		font-size: 12pt
		width: 270px

	.BlurryPhoto img
		width: 200px
		height: 260px

@media screen and (max-width: 350px)
	#About__description
		font-size: 12pt
		width: 200px

	#About__group-photo img
		width: 90%
