@media screen and (max-width: 1000px)
	#NotFound__welcome
		font-size: 28pt

	#NotFound__description
		font-size: 20pt

@media screen and (max-width: 750px)
	#NotFound__welcome
		font-size: 20pt

	#NotFound__description
		font-size: 18pt

@media screen and (max-width: 550px)
	#NotFound__welcome
		font-size: 16pt

	#NotFound__description
		font-size: 14pt
		width: 350px

	#NotFound__button
		width: 350px

@media screen and (max-width: 400px)
	#NotFound__welcome
		font-size: 14pt
		margin: 0 20px

	#NotFound__description
		font-size: 12pt
		width: 200px

	#NotFound__button
		width: 200px